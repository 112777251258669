import axios from "axios";
import qs from "qs";
import AppleLogin from "react-apple-login";
import appleButtonImg from "../img/apple-button-x1.png";

function AppleLoginButton() {
  const queryParameters = new URLSearchParams(window.location.search);
  let appleClientId;
  let appleRedirectUri;

  const appleResponse = (response) => {
    let state = queryParameters.get("state");
    if (state) {
      let stateSpl = state.split(" ");
      if (stateSpl[0] && stateSpl[1]) {
        appleClientId = stateSpl[0].split("applicationClientId=")[1];
        appleRedirectUri = stateSpl[1].split("applicationRedirectUri=")[1];
      }
    }
    if (!response.error && appleClientId && appleRedirectUri) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/web_apple`,
          qs.stringify({
            authCode: response.code,
            applicationClientId: appleClientId,
            applicationRedirectUri: appleRedirectUri,
          })
        )
        .then((success) => {
          window.location.href = `${success.data.redirect_uri}?code=${encodeURIComponent(
            success.data.code
          )}&expires_at=${success.data.expires_at}`;
        })
        .catch((err) => console.log("err", err));
    }
  };

  return (
    <AppleLogin
      clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
      redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URI}
      usePopup={false}
      callback={(res) => appleResponse(res)} // Catch the response
      scope="email"
      state={`applicationClientId=${queryParameters.get("client_id")}+applicationRedirectUri=${queryParameters.get(
        "redirect_uri"
      )}`}
      responseType="code"
      responseMode="query"
      render={(
        renderProps //Custom Apple Sign in Button
      ) => (
        <button
          onClick={renderProps.onClick}
          className="btn"
          style={{
            backgroundColor: "black",
            border: "0px solid black",
            fontFamily: "none",
            lineHeight: "25px",
            fontSize: "25px",
          }}
        >
          <img src={appleButtonImg} width={200} />
        </button>
      )}
    />
  );
}

export default AppleLoginButton;
